
import { ReactComponent as Sun } from '../sun.svg';
import { ReactComponent as Moon } from '../moon.svg';
const NightDaySlider = ({lightBackground, setLightBackground}) => {
    const handleChange = () => {
        setLightBackground(!lightBackground);
    }
    return (
        <>
        <div className="background-switch">
        <Sun />
        <label className="switch">
        <input type="checkbox"  onClick={handleChange}/>
        <span className="slider round"></span>
        </label> 
        <Moon />
        </div>
        </>
    );
  }
  
  export default NightDaySlider;