const SalaryInput = ({setMonthlySalary, monthlySalary, lightBackground}) => {
   
    return (
        <div className="monthlySalary">
            <label className="monthlySalaryLabel">
            <div className={`${lightBackground ? 'light' : 'dark' }`}>
            <input type="number" value={monthlySalary} onChange={(event) => setMonthlySalary(event.target.value)} placeholder="Monthly Salary"/>
            </div>
           
        </label>
        </div>
    );
  }
  
  export default SalaryInput;