import { ReactComponent as Cross } from '../x.svg';

const BillsInput = ({setBillValue, billValue, billName, setBillName, keyValue, lightBackground}) => {
    const handleBillValueChange = (event, keyValue) => {
    const value = event.target.value;
    const tempBillsInputBoxes = [...billValue];

    // Allow only numbers and up to two decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    
    if (regex.test(value) || value === '') {
        tempBillsInputBoxes[keyValue] = event.target.value;

        setBillValue(tempBillsInputBoxes);
    }
}
    
    const handleBillNameChange = (event, keyValue) => {
        const tempBillNames = [...billName];

        tempBillNames[keyValue] = event.target.value;

        setBillName(tempBillNames);
    }

  

    const handleRemoveBill = (event, keyValue) => {
        const tempBillName = [...billName];
        const tempBillValue = [...billValue];

        const newBillName = tempBillName.filter((bill, key) => key !== keyValue);
        const newBillValue = tempBillValue.filter((value, key) => key !== keyValue);

        setBillValue(newBillValue);
        setBillName(newBillName);
    }
    
    

    return (
        <div className="billsInput">
            <label className="billInputLabel">
            <div className={`${lightBackground ? 'light' : 'dark' }`}>
            <input type="text" value={billName[keyValue]} onChange={(event) => handleBillNameChange(event, keyValue)} placeholder="Bill Name"/>
            <input type="text" value={billValue[keyValue]} onChange={(event) => handleBillValueChange(event, keyValue)}  placeholder="0.00"/>
            <Cross onClick={(event) => handleRemoveBill(event, keyValue)} />
            </div>
           
        </label>
        </div>
    );
  }
  
  export default BillsInput;