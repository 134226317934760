import './App.css';
import NightDaySlider from './components/nightDaySlider';
import BillsInput from './components/billsInput';
import SalaryInput from './components/salaryInput';
import DonutChart from './components/donutChart';
import { useState } from 'react';
import generateRandomColor from './utilFunctions';

function FinanceCalc() {
  const [lightBackground, setLightBackground] = useState(true);
  const [billValue, setBillValue] = useState([0.00]);
  const [billName, setBillName] = useState(['']);
  const [monthlySalary, setMonthlySalary] = useState(0.00);

const handleButtonClick = () => {
  const tempBillName = [...billName];
  const tempBillValue = [...billValue];

  tempBillName.push('');
  tempBillValue.push(0.00);
  setBillValue(tempBillValue);
  setBillName(tempBillName);
}

const coloursArray = [];
billName.map((bill) => {
 return coloursArray.push(generateRandomColor());
});

coloursArray.push('rgba(46, 204, 113, 1)');

// Using reduce to convert strings to numbers, round to two decimal places, and add them up
let totalValue = billValue.reduce((accumulator, currentValue) => {
  // Convert the current value to a number using parseFloat or Number
  const numValue = parseFloat(currentValue);
  
  // Check if the conversion was successful and add the number to the accumulator
  if (!isNaN(numValue)) {
    // Round the number to two decimal places and add it to the accumulator
    return accumulator + numValue;
  } else {
    // If conversion fails, return accumulator as is
    return accumulator;
  }
}, 0); // Initialize accumulator with 0

totalValue = Math.round(totalValue * 100) / 100;

const getLeftOverDisplay = () => {
  const currentValues = [...billValue];
  const currentNames = [...billName];

  const amountLeft = monthlySalary - totalValue;

  currentValues.push(`${amountLeft}`);
  currentNames.push('Left Over Pay');

  return [currentValues, currentNames];
}


console.log(getLeftOverDisplay());

const chartData = {
  labels: getLeftOverDisplay()[1],
  datasets: [{
    label: 'Fiance Calculator',
    data: getLeftOverDisplay()[0],
    backgroundColor: coloursArray,
    borderColor: coloursArray,
    borderWidth: 0
  }]
};


return (
    <div className={`income-calc ${lightBackground ? 'light' : 'dark' }`}>
      <div className="income-calc_header">
        <span>Finance Calculator</span>
        <NightDaySlider lightBackground={lightBackground} setLightBackground={setLightBackground} />
      </div>
      <div className="income-calc_body">
        <div className="income-calc_body_column">
         <div className={`income-calc_monthly_pay ${monthlySalary > 0 ? 'salary' : ''}`}>
         <span>Enter Your </span> Monthly Pay 
         <div className="income-calc_salary_input">£<SalaryInput setMonthlySalary={setMonthlySalary} monthlySalary={monthlySalary} lightBackground={lightBackground} /></div>
          </div> 
          {monthlySalary > 0 && (
            <>
            {billName.map((bill, key) => {
              return <BillsInput setBillValue={setBillValue} billValue={billValue} billName={billName} setBillName={setBillName} key={key} keyValue={key} lightBackground={lightBackground}  />
            })}
            <button className={`income_calc_add_bill ${lightBackground ? 'light' : 'dark' }`} onClick={handleButtonClick}>Add Bill +</button>
            </>
          )}
          
        </div>
        <div className="income-calc_body_column result">
          <span className="income-calc_body_total">Total Outgoing £{totalValue}</span>
          <span className="income-calc_body_left">Pay Left £{monthlySalary - totalValue}</span>
          <span className="income-calc_body_donut">
          <DonutChart data={chartData} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default FinanceCalc;
