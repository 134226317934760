function generateRandomColor() {
   // Generating random value for the blue channel (150-255)
  const blue = Math.floor(Math.random() * 106) + 150; // 150 to 255 (inclusive)

  // Generating a random alpha value (opacity) between 0.1 and 1.0
  const alpha = Math.random() * (1 - 0.1) + 0.1;

  // Constructing the RGBA string with low red and green values, and a high blue value
  const color = 'rgba(0, 0, ' + blue + ', ' + alpha.toFixed(2) + ')';

  return color;
  }

export default generateRandomColor;