import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DonutChart = ({ data }) => {
  const chartRef = useRef();
 
  useEffect(() => {
    let chartInstance = null;
    const myChartRef = chartRef.current.getContext('2d');

    if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(myChartRef, {
      type: 'doughnut',
      data: data,
      options: {
        // Add options here
      }
    });


    return () => {
        chartInstance.destroy();
      };
  }, [data]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default DonutChart;